$light-gray: #f0f0f0;
$medium-gray: #7d7d7d;
$dark-gray: #212121;
$black: #000000;
$white: #ffffff;
$dark-nav-color: #2C3840;
$links: #1964c1;
$bright-blue: #008dd6;
$bright-green: #93cf2a;
$dark-blue: #134686;

$foundation-palette: (
  primary: $dark-blue,
  secondary: $bright-blue,
  success: $bright-green,
  warning: #ffae00,
  alert: #cc4b37,
);