#cookie-notice {
	color: $white !important;
	background-color: rgba(0,0,0,0.85) !important;

	.cookie-notice-container {
		@include clearfix;
		width: 100%;
		max-width: rem-calc(500);
		margin: 0 auto;
	}

	#cn-notice-text {
		display: block;
		float: left;
		width: 55%;
		text-align: left;
		line-height: 1.3em;
		font-size: rem-calc(13);
	}

	#cn-accept-cookie {
		display: block;
		float: right;
		width: 33%;
		max-width: rem-calc(120);
		font-size: rem-calc(16);
		padding: 0.66em 1em;
		background-color: $bright-blue;
		color: $white;
		border-color: $bright-blue;
		margin: 0;
	}
}

@include breakpoint(large) {
	#cookie-notice {
		.cookie-notice-container {
			@include clearfix;
			max-width: rem-calc(675);
		}

		#cn-notice-text {
			width: 80%;
			margin-top: rem-calc(8);
		}

		#cn-accept-cookie {
			max-width: rem-calc(100);
			font-size: rem-calc(14);
		}
	}
}