.main-content,
article.page .entry-content {
  @include xy-grid-container;
}

.main-content article.post,
article.page .entry-content .entry-content-wrap {
  @include xy-cell(12);

  header {
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(60);
  }

  .entry-title {
    font-family: $body-font-family;
    font-weight: 400;
    font-size: rem-calc(36);
    overflow-wrap: break-word;
    text-align: center;

    a {
      color: $bright-blue;
    }
  }

  .entry-content {
    @include clearfix;

    img {
      max-width: 100%;
    }
  }

  img.wp-post-image {
    margin: 0 0 rem-calc(40) 0;
    display: block;
    width: 100%;
    height: auto;
  }

  blockquote {
    margin-top: rem-calc(60);
    margin-bottom: rem-calc(60);
    border: 0;
    padding: rem-calc(30) rem-calc(30) rem-calc(20) rem-calc(30);
    position: relative;
    text-align: center;
    font-size: rem-calc(22);

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 30%;
      border-top: 1px solid $medium-gray;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 30%;
      border-bottom: 1px solid $medium-gray;
    }
  }
}

.main-content {
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(40);

  article.post {
    time.updated,
    .byline {
      font-size: rem-calc(12);
      color: $medium-gray;
      display: none;

      a {
        color: $medium-gray;

        &:hover {
          color: $dark-gray;
        }
      }
    }
  }

  article.featured-post {
    @include xy-cell(12);
    position: relative;
    background-size: cover;
    background-position: center;
    background-color: $light-gray;
    margin-bottom: rem-calc(10);

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.6) 30%, rgba(0,0,0,0.7) 100%);
    }

    .featured-post-summary {
      color: $white;
      position: relative;
      padding: rem-calc(20);
      z-index: 2;

      a.featured-post-link {
        display: none;
      }
    }

    h2 {
      color: $white;
      text-shadow: 0px 2px 5px rgba(0,0,0,0.5);
      font-family: 'Open Sans Condensed', sans-serif;
      font-weight: bold;
      font-size: rem-calc(30);
      margin-bottom: rem-calc(20);
    }

    .excerpt {
      line-height: 1.4em;
    }

    a.link-with-arrow {
      text-transform: uppercase;
      font-size: rem-calc(16);
      color: $white;
      font-weight: bold;
      display: inline-block;
      margin-top: rem-calc(20);

      .icon {
        border: 2px solid $white;
        border-radius: 50%;
        width: 1.6em;
        height: 1.6em;
        position: relative;
        display: inline-block;
        margin-left: 0.33em;
        top: 0.45em;

        .fa {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }

    a.featured-post-link {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
  }
}

article.page .entry-content {
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(20);
}

@include breakpoint(medium) {
  .main-content article.post,
  article.page .entry-content .entry-content-wrap {
    img.wp-post-image {
      float: right;
      margin: 0 0 rem-calc(30) rem-calc(30);
      max-width: 300px;
    }
  }
}

@include breakpoint(rem-calc(800)) {
  .main-content {
    article.featured-post {
      margin-bottom: rem-calc(20);
      padding: rem-calc(20);

      &:after {
        display: none;
      }

      .featured-post-summary {
        width: 75%;
        background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.8) 100%);

        a.featured-post-link {
          display: block;
        }
      }

      h2 {
        font-size: rem-calc(34);
      }

      a.featured-post-link {
        display: none;
      }
    }
  }
}

@include breakpoint(large) {
  .main-content article.post,
  article.page .entry-content .entry-content-wrap {
    @include xy-cell(10);
    @include xy-cell-offset(1);

    img.wp-post-image {
      margin: 0 0 rem-calc(40) rem-calc(40);
      max-width: 420px;
    }
  }

  .main-content {
    article.featured-post {
      .featured-post-summary {
        width: 50%;
        max-width: rem-calc(420);
      }

      h2 {
        font-size: rem-calc(38);
      }
    }
  }
}