.content-block.text-with-icons {
	padding-top: rem-calc(48);
	padding-bottom: rem-calc(55);
	
	&.gray {
		background-color: $light-gray;
	}

	&.white {
		background-color: $white;
	}

	.block-inner-content {
		@include xy-cell(12);
		text-align: center;
	}

	h2 {
		margin-bottom: 1em;
	}

	h3 {
		margin-top: 2em;
	}

	p {
		font-size: rem-calc(20);
	}

	.icons {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		margin: rem-calc(20) 0 0 0;

		li {
			flex: 1 1 50%;
			padding: rem-calc(10);

			img {
				width: auto;
				height: rem-calc(60);
				display: block;
				margin: 0 auto rem-calc(20) auto;
			}

			p {
				font-size: rem-calc(12);
			}
		}
	}
}

@include breakpoint(medium) {
	.content-block.text-with-icons {
		.icons li {
			flex: 1;
		}
	}
}

@include breakpoint(large) {
	.content-block.text-with-icons {
		.block-inner-content {
			@include xy-cell(10);
			@include xy-cell-offset(1);
		}

		.icons li {
			flex: 1;
		}
	}
}