.contact-form form,
.contact-form .caldera-grid form {
	input:not([type=submit]):not([type=file]):not([type=checkbox]),
	select,
	textarea,
	.form-control {
		border-radius: 0;
		border: 1px solid $medium-gray;
		box-shadow: none;

		&:focus {
			border-color: $medium-gray;
			box-shadow: none;
		}
	}

	label {
		&:focus {
			outline: none;
		}

		&.checkbox-inline input {
			margin-right: 0.6em;
		}
	}

	h4 {
		color: $bright-blue;
		margin-top: 1em;
	}

	input[type=submit] {
		font-size: rem-calc(20);
		width: 100%;
		margin-top: 1em;
	}

	.dropdown {
		position: relative;

		select {
			padding-right: rem-calc(30);
		}

		i {
			position: absolute;
			right: rem-calc(10);
			top: 50%;
			transform: translateY(-50%);
			pointer-events: none;
		}
	}
}