.main-content .category-posts,
.related-posts {
	@include xy-grid;
	border-top: 1px solid $medium-gray;

	&:nth-of-type(1){
		border-top: none;
	}

	h2 {
		@include xy-cell(12);
		text-align: center;
		margin-top: rem-calc(42);
		margin-bottom: rem-calc(42);

		a,
		a:hover,
		a:visited {
			color: $bright-blue;
		}
	}

	.link-with-arrow {
		@include xy-cell(12);
		padding-top: rem-calc(0);
	}

	article.post {
		@include xy-cell(12);
		margin-bottom: rem-calc(40);

		&:nth-last-of-type(1) {
			margin-bottom: 0;
		}

		header {
			margin: 0;
		}

		.featured-image {
			display: block;
			width: 100%;
			background-size: cover;
			background-position: center;
			background-color: $light-gray;

			&:after {
				content: "";
				display: block;
				width: 100%;
				padding-top: 58%;
			}
		}

		h2 {
			font-size: rem-calc(14);
			margin: 1.5em 0 0.25em 0;
			text-align: left;
		}

		.excerpt,
		.read-more {
			font-size: rem-calc(12);
		}

		.excerpt {
			margin: 0;
		}
	}
}

@include breakpoint(medium) {
	.main-content .category-posts,
	.related-posts {

	}
}

@include breakpoint(large) {
	.main-content .category-posts,
	.related-posts {
		max-width: rem-calc(1000);
		margin: 0 auto;

		article.post {
			@include xy-cell(4);
		}
	}
}