.content-block.timeline {
	padding-top: rem-calc(48);
	padding-bottom: rem-calc(55);

	.block-inner-content {
		@include xy-cell(12);
	}

	h2 {
		text-align: center;
	}

	ol.timeline-points {
		margin: rem-calc(50) 0 0 0;
		list-style: none;
		position: relative;

		&:before {
			content: "";
			display: block;
			position: absolute;
			left: rem-calc(11);
			top: rem-calc(2);
			width: 0;
			height: 100%;
			border-left: 1px dotted $medium-gray;
		}

		li {
			margin: 0 0 rem-calc(40) 0;
			padding: 0 0 0 rem-calc(32);
			position: relative;

			&:before {
				content: "";
				display: block;
				position: absolute;
				left: rem-calc(4);
				top: rem-calc(4);
				width: rem-calc(16);
				height: rem-calc(16);
				transform-origin: center center;
				transform: rotate(45deg) translateX(-0.5px);
				background-color: $bright-blue;
				z-index: 2;
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				left: rem-calc(1);
				top: rem-calc(1);
				width: rem-calc(22);
				height: rem-calc(22);
				transform-origin: center center;
				transform: rotate(45deg) translateX(-0.5px);
				z-index: 1;
			}
		}
	}

	&.white {
		background-color: $white;

		ol.timeline-points {
			li {
				&:after {
					background-color: $white;
				}
			}
		}
	}

	&.light-gray {
		background-color: $light-gray;

		ol.timeline-points {
			li {
				&:after {
					background-color: $light-gray;
				}
			}
		}
	}
}

@include breakpoint(medium) {
	.content-block.timeline {
		.block-inner-content {
			@include xy-cell(10);
			@include xy-cell-offset(1);
		}

		ol.timeline-points {
			&:before {
				left: rem-calc(81);
			}

			li {
				padding: 0;

				&:before {
					left: rem-calc(74);
					top: rem-calc(7);
				}

				&:after {
					left: rem-calc(71);
					top: rem-calc(4);
				}

				.date {
					position: absolute;
					left: 0;
					top: 0;
				}

				.description {
					padding-left: rem-calc(110);
				}
			}
		}
	}
}

@include breakpoint(large) {
	.content-block.timeline {
		.block-inner-content {
			@include xy-cell(8);
			@include xy-cell-offset(2);
		}

		ol.timeline-points {
			&:before {
				left: 50%;
			}

			li {
				&:before {
					left: 50%;
					top: rem-calc(7);
					transform: translateX(-7.5px) rotate(45deg);
				}

				&:after {
					left: 50%;
					top: rem-calc(4);
					transform: translateX(-10.5px) rotate(45deg);
				}

				.date {
					left: auto;
					right: 55%;
				}

				.description {
					padding-left: 55%;
				}
			}

			li:nth-of-type(even){
				.date {
					left: 55%;
					right: auto;
				}

				.description {
					padding-right: 55%;
					padding-left: 0;
					text-align: right;
				}
			}
		}
	}
}