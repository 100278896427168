.content-block.spacer {
	padding-top: rem-calc(20);
	padding-bottom: rem-calc(20);

	.block-inner-content {
		@include xy-cell(10);
		@include xy-cell-offset(1);
	}

	hr {
		border: none;
		border-bottom: 1px solid $medium-gray;
		margin: rem-calc(10) 0;
		opacity: 0.5;
	}
}

@include breakpoint(medium) {
	.content-block.spacer {
		
	}
}

@include breakpoint(large) {
	.content-block.spacer {
		padding-top: rem-calc(30);
		padding-bottom: rem-calc(30);

		.block-inner-content {
			@include xy-cell(8);
			@include xy-cell-offset(2);
		}
	}
}