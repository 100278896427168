.content-block.link-with-arrow {
	background-color: $white;
	text-align: center;
	text-transform: uppercase;
	padding: rem-calc(45) rem-calc(30);
	font-size: rem-calc(20);
	line-height: 0.75em;

	a {
		color: $bright-blue;
	}

	.icon {
		border: 1px solid $bright-blue;
		border-radius: 50%;
		width: 1.6em;
		height: 1.6em;
		position: relative;
		display: inline-block;
		margin-left: 0.33em;
		top: 0.45em;

		.fa {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}
}

@include breakpoint(medium) {
	.content-block.link-with-arrow {
		
	}
}

@include breakpoint(large) {
	.content-block.link-with-arrow {
		
	}
}