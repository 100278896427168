.content-block.product-details {
	padding-top: rem-calc(30);
	padding-bottom: rem-calc(30);

	h1 {
		font-size: rem-calc(32);
		text-align: center;
		font-family: 'Open Sans', sans-serif;
		font-weight: 400;
		margin-bottom: rem-calc(40);
	}

	.block-inner-content {
		@include xy-grid;
	}

	.product-images,
	.product-description {
		@include xy-cell(12);

		a.brochure {
			padding-right: 45px;
		}
	}

	.product-images {
		margin-bottom: rem-calc(40);

		.current-image {
			width: 100%;
			padding-top: 80%;
			position: relative;
			margin-bottom: rem-calc(20);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		}

		.image-thumbnails {
			list-style: none;
			margin: 0;
			padding: 0;
			@include clearfix;

			li {
				width: (1/5) * 100%;
				float: left;
				padding-top: 12%;
				position: relative;
				overflow: hidden;

				a {
					display: block;
					width: 90%;
					height: 100%;
					position: absolute;
					left: 50%;
					top: 0;
					transform: translateX(-50%);
					border: 3px solid white;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;

					&.active {
						border: 3px solid $bright-green;

						&:hover {
							border: 3px solid $bright-green;
						}
					}

					&:hover {
						border: 3px solid $light-gray;
					}
				}
			}
		}
	}
}

@include breakpoint(medium) {
	.content-block.product-details {
		.product-images {
			@include xy-cell(7);
			margin-bottom: 0;
		}

		.product-description {
			@include xy-cell(5);

		}
	}
}

@include breakpoint(large) {
	.content-block.product-details {
		.product-images {
			@include xy-cell(5.5);
			@include xy-cell-offset(1);
		}

		.product-description {
			@include xy-cell(4.5);
		}
	}
}