.content-block.anchor-jump-menu {
	padding-top: rem-calc(20);
	padding-bottom: rem-calc(20);
	background-color: $light-gray;

	.block-inner-content {
		@include xy-cell(12);
		text-align: center;
	}

	h5 {
		font-size: rem-calc(14);
		color: $medium-gray;
	}

	ul {
		margin: 0;
		list-style: none;

		li {
			display: inline-block;
			margin: 0 1em;

			a {
				font-size: rem-calc(14);

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

@include breakpoint(medium) {
	.content-block.anchor-jump-menu {
		
	}
}

@include breakpoint(large) {
	.content-block.anchor-jump-menu {
		
	}
}