.content-block.button-menu-with-title {
	padding-top: rem-calc(48);
	padding-bottom: rem-calc(48);
	background-color: $light-gray;

	.block-inner-content {
		@include xy-cell(12);
		text-align: center;
	}

	h2 {
		text-align: center;
	}

	.menu {
		list-style: none;
		margin: rem-calc(30) auto 0 auto;
		@include xy-grid;
		max-width: rem-calc(500);

		li {
			@include xy-cell(12);
			margin-bottom: rem-calc(20);
		}
	}

	.button {
		background-color: $dark-blue;
		color: $white;
		border: 1px solid $dark-blue;

		&:hover {
			background-color: transparent;
			color: $bright-blue;
		}
	}
}

@include breakpoint(medium) {
	.content-block.button-menu-with-title {
		.block-inner-content {
			@include xy-cell(10);
			@include xy-cell-offset(1);
		}
	}
}

@include breakpoint(large) {
	.content-block.button-menu-with-title {
		.menu {
			max-width: 100%;

			li {
				@include xy-cell(6);
			}
		}
	}
}