.content-block.button-box {
	background-color: $dark-blue;
	text-align: center;
	padding: rem-calc(45) rem-calc(30);

	.button {
		margin: 0 auto;
		color: $white;
	}
}

@include breakpoint(medium) {
	.content-block.button-box {
		
	}
}

@include breakpoint(large) {
	.content-block.button-box {
		
	}
}