.content-block.form-with-title {
	padding-top: rem-calc(48);
	padding-bottom: rem-calc(48);
	background-color: $dark-blue;
	color: $white;


	// Hide on-page toughbook selector caldera form. There is jquery in the 
	// toughbookSelector.js that also hides the parent "Form With Title Content" block
	#customselector_caldera_form {
		border: 1px solid red;
		// display: none;
	}
	&.hide {
		// display: none;
	}

	.block-inner-content {
		@include xy-cell(12);
		text-align: center;
	}

	h2 {
		text-align: center;
		color: $white;
		font-size: rem-calc(24);
		margin-bottom: rem-calc(32);
	}

	form {
		.field-comments textarea {
			height: rem-calc(83);
		}

		label {
			color: $white;
		}

		.checkbox {
			label {
				padding-left: 0;
			}

			input {
				margin-left: 0;
				margin-right: rem-calc(10);
			}
		}

		.field-submit {
			margin-top: rem-calc(20);
		}

		.button {
			color: $white;
			padding-left: 2.5em;
			padding-right: 2.5em;
		}

		.help-block {
			color: $white;
		}
	}
}

@include breakpoint(medium) {
	.content-block.form-with-title {
		.block-inner-content {
			@include xy-cell(10);
			@include xy-cell-offset(1);
		}
	}
}

@include breakpoint(large) {
	.content-block.form-with-title {
		.block-inner-content {
			@include xy-cell(8);
			@include xy-cell-offset(2);
		}
	}
}