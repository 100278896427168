.content-block.product-table {
	table {
		font-size: rem-calc(12);

		thead {
			background: transparent;
		}

		th, td {
			border: 2px solid $white;
		}

		th {
			font-weight: 400;
			color: $bright-blue;
			text-transform: uppercase;
			vertical-align: bottom;
			background-color: transparent;
			text-align: center;

			&:nth-of-type(1){
				text-align: left;
			}

			img {
				display: block;
				margin: 0 auto;
				width: rem-calc(100);
				height: auto;
			}

			a {
				color: $bright-blue;
				text-decoration: underline;
			}
		}

		tbody {
			td {
				text-align: center;
			}

			td:nth-of-type(1){
				text-align: left;
			}
		}

		i.fa-check {
			font-size: 1.5em;
			line-height: 0.8em;
			color: $bright-green;
		}
	}

	.notes {
		font-size: rem-calc(10);
	}
}

@include breakpoint(medium) {
	.content-block.product-table {
		table {
			font-size: rem-calc(14);
		}

		.notes {
			font-size: rem-calc(12);
		}
	}
}

@include breakpoint(large) {
	.content-block.product-table {
		
	}
}