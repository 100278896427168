.content-block.picture-grid {
	@include clearfix;

	.block-wrap {
		@include xy-grid-container($grid-container, 0);
	}

	.picture-grid-picture {
		width: 100%;
		background-size: cover;
		background-position: center;
		position: relative;
		overflow: hidden;

		&.center {
			text-align: center;
		}

		&.left {
			text-align: left;
		}

		&:before {
			content: "";
			display: block;
			width: 100%;
			padding-top: 75%;
			position: relative;
			z-index: 1;
		}

		.picture-grid-content {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			padding: rem-calc(20);
			background-color: rgba(19,70,134,0);
			font-size: (11/400) * 100vw;
			transition: background 0.25s ease;

			h3, ul {
				position: relative;
				z-index: 1;
				color: $white;
			}

			h3 {
				display: inline-block;
				font-size: (28/11) * 1em;
				text-shadow: 0px 2px 5px rgba(0,0,0,0.5);
				border-bottom: 3px solid transparent;
				margin: 0 0 0.5em 0;
				padding: 0 0 0.2em 0;
				transition: border 0.25s ease;
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				text-transform: uppercase;
				font-size: 1em;
				line-height: 2.2em;
				opacity: 0;
				transition: opacity 0.25s ease;
				

				a {
					color: $white;

					&:hover {
						color: $bright-green;
					}
				}
			}

			p {
				margin: 0;
				padding: 0;
				// text-transform: uppercase;
				font-size: 1em;
				line-height: 2.2em;
				opacity: 0;
				transition: opacity 0.25s ease;
				color: $white;
				border: 1px solid red;

			}

			a.picture-link {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
			}

			&:hover {
				background-color: rgba(19,70,134,0.8);

				h3 {
					border-bottom: 3px solid $bright-green;
				}

				ul {
					opacity: 1;
				}
				p {
					opacity: 1;
				}
			}
		}
	}
}

@include breakpoint(medium) {
	.content-block.picture-grid {
		.picture-grid-picture {
			&:before {
				padding-top: 62.5%;
			}

			.picture-grid-content {
				font-size: (14/600) * 100vw;
			}
		}
	}
}

@include breakpoint(rem-calc(700)) {
	.content-block.picture-grid {
		.picture-grid-picture {
			width: 50%;
			float: left;

			&:before {
				padding-top: 92%;
			}

			.picture-grid-content {
				font-size: (12/700) * 100vw;
			}
		}
	}
}

@include breakpoint(large) {
	.content-block.picture-grid {
		.picture-grid-picture {
			width: 50%;
			float: left;

			&:before {
				padding-top: 66.67%;
			}

			.picture-grid-content {
				font-size: (11/900) * 100vw;
			}
		}
	}
}

@include breakpoint(xlarge) {
	.content-block.picture-grid {
		.block-wrap {
			@include xy-grid-container();
		}

		.picture-grid-picture {
			width: 50%;
			float: left;

			&:before {
				padding-top: 62.5%;
			}

			.picture-grid-content {
				padding: (30/1200) * 100vw;
				font-size: 14px;
			}
		}
	}
}