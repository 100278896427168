.content-block.product-type-summary {
	.block-inner-content {
		@include xy-grid;
		padding-top: rem-calc(48);
		padding-bottom: rem-calc(48);
		border-top: 1px solid $medium-gray;
	}

	.product-image,
	.product-summary {
		@include xy-cell(12);
	}

	.product-image {
		width: 100%;
		height: rem-calc(200);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		margin-bottom: rem-calc(20);

		a {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.product-summary {
		.product-summary-content {
			position: relative;
			// top: 50%;
			// transform: translateY(-50%);
		}

		h3 {
			a {
				color: $bright-blue;
			}
		}

		p.description {
			font-size: rem-calc(14);
		}

		a.link-with-arrow {
			text-transform: uppercase;
			font-size: rem-calc(16);
			color: $bright-blue;

			.icon {
				border: 1px solid $bright-blue;
				border-radius: 50%;
				width: 1.6em;
				height: 1.6em;
				position: relative;
				display: inline-block;
				margin-left: 0.33em;
				top: 0.45em;

				.fa {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%) translateY(-50%);
				}
			}
		}
	}

	&.first{
		.block-inner-content {
			border-top: 0;
			padding-top: 0;
		}
	}
}

@include breakpoint(medium) {
	.content-block.product-type-summary {
		.product-image,
		.product-summary {
			@include xy-cell(6);
		}

		.product-image {
			height: rem-calc(250);
			margin-bottom: 0;
		}

		&.right {
			.product-image {
				order: 2;
			}

			.product-summary {
				order: 1;
			}
		}
	}
}

@include breakpoint(large) {
	.content-block.product-type-summary {
		.product-summary {
			a.link-with-arrow {
				font-size: rem-calc(18);
			}
		}
	}
}