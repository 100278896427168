.content-block.quotes {
	padding-top: rem-calc(48);
	padding-bottom: rem-calc(55);

	&.white {
		background-color: $white;
	}

	&.gray {
		background-color: $light-gray;
	}

	h2,
	.orbit {
		@include xy-cell(12);
		text-align: center;
	}

	h2 {
		margin-bottom: 0.66em;
	}

	.orbit {
		margin-top: 1.75em;
	}

	.quotemark {
		display: block;
		font-family: "Georgia", "Times New Roman", serif;
		color: $bright-green;
		font-size: rem-calc(100);
		width: rem-calc(66);
		height: rem-calc(66);
		border: 1px solid $bright-blue;
		border-radius: 50%;
		position: relative;
		overflow: hidden;
		margin: 0 auto;

		.char {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-33%);
		}
	}

	blockquote,
	cite {
		color: $black;
	}

	blockquote {
		border: 0;
		margin: 0;
		padding: 0;
		font-size: rem-calc(20);
	}

	cite {
		font-style: normal;
		margin-top: 1em;
		font-size: rem-calc(18);
	}

	.orbit-controls {
		display: none;
	}
}

@include breakpoint(medium) {
	.content-block.quotes {
		h2,
		.orbit {
			@include xy-cell(10);
			@include xy-cell-offset(1);
		}

		.orbit-controls {
			display: block;

			.orbit-previous,
			.orbit-next {
				color: $medium-gray;
				font-size: rem-calc(28);
				cursor: pointer;
				transition: opacity 0.25s ease;
				opacity: 0;
			}

			.orbit-previous {
				right: 100%;
				left: auto;
				margin-right: rem-calc(20);
			}

			.orbit-next {
				left: 100%;
				right: auto;
				margin-left: rem-calc(20);
			}
		}

		.orbit:hover {
			.orbit-controls {
				.orbit-previous,
				.orbit-next {
					opacity: 0.5;

					&:hover {
						opacity: 0.75;
					}
				}
			}
		}
	}
}

@include breakpoint(large) {
	.content-block.quotes {
		h2,
		.orbit {
			@include xy-cell(8);
			@include xy-cell-offset(2);
		}

		.orbit-controls {
			.orbit-previous {
				margin-right: rem-calc(60);
			}

			.orbit-next {
				margin-left: rem-calc(60);
			}
		}
	}
}