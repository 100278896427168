.contact-page {
	@include xy-grid-container;
	margin-top: rem-calc(40);
	margin-bottom: rem-calc(50);

	.content-inner {
		@include xy-grid;
	}

	.contact-content,
	.contact-form,
	.contact-addresses {
		@include xy-cell(12);
	}

	.contact-content {
		h1 {
			font-family: inherit;
			font-size: rem-calc(32);
			font-weight: normal;
			margin-bottom: 0.75em;
		}

		h2 {
			font-size: rem-calc(20);
		}

		p {
			font-size: rem-calc(16);
		}

		.contact-addresses {
			margin: rem-calc(30) 0 0 0;
			width: 100%;
		}
	}

	.contact-form {
		padding-top: rem-calc(20);
	}

	.contact-addresses {
		margin-top: rem-calc(40);

		h3 {
			font-size: rem-calc(20);
		}

		.locations {
			margin: 1em 0 0 0;
			list-style: none;
			font-size: rem-calc(16);

			li {
				margin-bottom: rem-calc(20);
				@include clearfix;
			}

			h4 {
				color: $bright-green;
				text-transform: initial;
				font-weight: 700;
				font-size: 1em;
				float: left;
				margin: 0;

				.fa {
					margin-left: 0.33em;
				}
			}

			.info {
				overflow: hidden;
				@include clearfix;
				clear: both;
				padding-top: 0.5em;
				padding-bottom: 0.5em;
			}

			.phone-number,
			address,
			.map-link,
			.fax-number {
				display: block;
				font-size: 1em;
				float: left;
				clear: both;
			}

			.phone-number {
				margin-bottom: 0.5em;
			}

			address {
				font-style: normal;
			}

			.map-link {
				color: $bright-blue;
				margin-top: 0.75em;

				i {
					color: $medium-gray;
					font-size: 0.8em;
				}

				&:hover {
					text-decoration: underline;
				}
			}

			.fax-number {
				margin-top: 0.5em;
			}
		}
	}
}

@include breakpoint(medium) {
	.contact-page {
		.contact-content,
		.contact-form,
		.contact-addresses {
			@include xy-cell(8);
			@include xy-cell-offset(2);
		}
	}
}

@include breakpoint(rem-calc(800)) {
	.contact-page {
		.contact-content,
		.contact-form,
		.contact-addresses {
			@include xy-cell(6);
			@include xy-cell-offset(0);
		}

		.contact-form {
			padding-top: rem-calc(60);
		}
	}
}

@include breakpoint(large) {}