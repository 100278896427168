.content-block.tab-container {
	border: 0 !important;
	padding: 0;

	// padding-top: rem-calc(48);
	// padding-bottom: rem-calc(55);

	.block-inner-content {
		
		border: 2px solid pink;
		margin-left: 50%;

		#example-tabs {
			border: 1px solid blue;
			// width: auto;
			margin-left: -75%;
			// display: block;
			// display: inline-block;
		}
		
		.tabs-title {

			a {
				background-color: $white;
				color: $bright-blue;
				border: 1px solid $bright-green;


				&:hover {
					color: $white;
					background-color: $bright-green;
				}
				
				color: $bright-blue;
				font-size: rem-calc(20);
				margin-left: 20px;
				margin-right: 20px;
			}
			&.is-active {
				a {
					color: $white;
					background-color: $bright-green;

				}
			}
		}

		

		.tabs-content {
			margin-left: -100%;

			border: 1px solid red;

			.text-content {
				@include xy-cell(6);
				border: 1px solid yellow;
			}
			.image-content {
				@include xy-cell(6);
				border: 1px solid green;
			}
		}
	}
	
}

@include breakpoint(medium) {
	.content-block.tab-container {
		.block-inner-content {
			// @include xy-cell(10);
			// @include xy-cell-offset(1);
		}

	}
}

@include breakpoint(large) {
	.content-block.tab-container {
		.block-inner-content {
			// @include xy-cell(8);
			// @include xy-cell-offset(2);
			
		}

	}
}