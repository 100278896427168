.content-block.hero-image-with-title {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding-bottom: rem-calc(100);
	position: relative;

	h1 {
		@include xy-cell(12);
		margin-top: rem-calc(75);
		color: $white;
		text-shadow: 0px 2px 5px rgba(0,0,0,0.5);

		br {
			display: none;
		}

		&.left {
			text-align: left;
		}

		&.right {
			text-align: right;
		}
	}

	.block-wrap {
		position: relative;
		z-index: 2;
	}

	&:after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 40%,rgba(0,0,0,0.5) 100%);
	}
}

@include breakpoint(medium) {
	.content-block.hero-image-with-title {
		h1 {
			@include xy-cell(8);

			br {
				display: inline;
			}

			&.right {
				@include xy-cell-offset(4);
			}

			&.full-width {
				@include xy-cell(12);
			}

			&.full-width.right {
				@include xy-cell-offset(0);
			}
		}
	}
}

@include breakpoint(large) {
	.content-block.hero-image-with-title {
		h1 {
			@include xy-cell(6);

			&.right {
				@include xy-cell-offset(6);
			}

			&.full-width {
				@include xy-cell(12);
			}

			&.full-width.right {
				@include xy-cell-offset(0);
			}
		}
	}
}