.content-block.recent-posts {
	padding-top: rem-calc(48);

	h2 {
		text-align: center;
		margin-bottom: 1em;
	}

	ul {
		list-style: none;
		margin: 0 auto;
		@include xy-grid;
		max-width: rem-calc(500);

		li {
			@include xy-cell(12);
			margin-bottom: rem-calc(40);

			&:nth-last-of-type(1) {
				margin-bottom: 0;
			}

			.featured-image {
				display: block;
				width: 100%;
				background-size: cover;
				background-position: center;

				&:after {
					content: "";
					display: block;
					width: 100%;
					padding-top: 58%;
				}
			}

			h6 {
				font-size: rem-calc(14);
				margin: 1.5em 0 0.25em 0;
			}

			.excerpt,
			.read-more {
				font-size: rem-calc(12);
			}

			.excerpt {
				margin: 0;
			}
		}
	}
}

@include breakpoint(medium) {
	.content-block.recent-posts {

	}
}

@include breakpoint(large) {
	.content-block.recent-posts {
		ul {
			max-width: rem-calc(1000);
			
			li {
				@include xy-cell(4);
			}
		}
	}
}