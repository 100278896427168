#device-selector-form {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: none;

	.caldera-grid {
	  .alert-success {
	    display: none;
	  }
	}

	.modal-overlay {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: $white;
		opacity: 0.9;
	}

	.selector-box {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: $white;
		z-index: 2;

		.close-x {
			color: $bright-green;
			position: absolute;
			right: 10px;
			top: 10px;
			font-size: 20px;
			cursor: pointer;
			width: 20px;
			text-align: center;
		}
	}
}

.title-with-text-and-button {
	#toughbook_selector, #radio_selector {
		color: $white;
		background-color: $bright-blue;

		&:hover {
			background-color: $bright-green;
		}
	}
}

.selector-box {
	// border: 1px solid $bright-green;
	border: 1px solid $bright-green;
	width: 90%;
	max-width: 900px;
	max-height: 95vh;
	overflow: auto;
	

	#caldera_form_1 {
	// #CF60565772099dc_1 {
		// border: 1px solid red;
		width: 70%;
		margin: 0px auto;
		// transform: translateX(-50%);

		
		#CF60565772099dc_1-row-1 {
			// border: 1px solid green;
			font-weight: 100;
		}
		#fld_6992851Label,
		#fld_9069527Label,
		#fld_4609800Label,
		#fld_9038984Label {
			font-weight: 100;
			// font-size: 20px;
		}
		
		.form-control {
			// border: 1px solid red;
			border: 1px solid $bright-green !important;
		}
		// width: 90%;
		// max-width: 900px;
		// max-height: 95vh;
		// overflow: auto;
		
		
	}

	p {
		text-align: center;
	}

	.selector-title {
		text-align: center;
		width: 90%;
		margin: 3rem auto;
	}

	.selector-list {
	    margin: 0 auto 2rem auto;
	    width: 100%; 

		.list-container {
			width: 75%;
			margin: 0 auto;

			li{
				list-style: none;
				margin-bottom: 1rem;
			}

			label {
				line-height: .8rem;
				
			}
		}
	}

	&.checkboxes {

		.selector-list {

			.list-container {

				label {
					height: auto;
					// border: 1px solid red;
					line-height: 0.8rem;
					margin-left: 0;
				}
				input[type=checkbox] { 
				    // visibility: hidden;
				    // float: left; 
				    vertical-align: top;
				    width: 5%;
					display: inline-block;
					border: 1px solid green;
					margin-top: 0.3rem;
					// float: none;
				} 
				p {
					// border: 1px solid yellow;
					width: 92%;
					// float: right;
					line-height: 1.2rem;
					display: inline-block;
					text-align: left;
					margin-bottom: 0;
				}
			}
		}
	}

	.button,
	.button:active,
	.button:focus,
	#fld_7707851_1 {
		background-color: $white;
		color: $bright-blue;

		&:hover {
			background-color: $bright-green;
			color: $white;
		}
	}

	input[type="radio"] {
		margin-bottom: 0.4em;
	}

	.back-next-buttons {
		text-align: center;

		.radio-back, .radio-next {
			padding: .5rem 2rem;
			margin-bottom: 2.5rem;
		}

		.radio-back {
			font-size: 1rem;
			margin-right: .5rem;
		}

		.radio-next {
			font-size: 1rem;
			margin-left: .5rem;
		}
	}

	.flex-wrapper {
		display: flex;
		justify-content: space-evenly;
		flex-direction: row;
		flex-wrap: wrap;
		width: 96%;
		margin: 0 auto;
			
		.device-wrapper {
			text-align: center;

			input[type="radio"] {
				display: none;
			}

			.device-type {
				cursor: pointer;

				.overlay {
					display: none;
					position: absolute;
				}
			}

			.device-type.clicked {
				.device-image {
					.overlay {
						display: block;
						height: 95%;
						width: 95%;
						background-color: $bright-blue;
						opacity: .7;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}

			.device-info {
				p {
					margin-top: 1rem;
					margin-bottom: 2rem;
				}

				.device-image {
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
					border: 1px solid $bright-green;
					width: 29vw;
					max-width: 150px;
					height: auto;
					position: relative;

					&:after {
						content: "";
						display: block;
						width: 100%;
						padding-top: 100%;
					}
				}

				#laptop {


					background-image: url("../images/toughbook-selector/55_Front_Left_Win10_resize.jpg");
				}

				#tablets {
					background-image: url("../images/toughbook-selector/fz-m1_mk3_front_hero-1.png");
				}

				#handhelds {
					background-image: url("../images/toughbook-selector/fz-n1_mk2_android_image_2_1.jpg");
				}

				#two-in-one {
					background-image: url("../images/toughbook-selector/tb_33_hero.png");
				}
			}
		}
	}

	.learn-more-button {
		text-transform: uppercase;
		font-size: rem-calc(16);
		color: $bright-blue;

		.icon {
			border: 1px solid $bright-blue;
			border-radius: 50%;
			width: 1.6em;
			height: 1.6em;
			position: relative;
			display: inline-block;
			margin-left: 0.33em;
			top: 0.45em;

			.fa {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translateX(-50%) translateY(-50%);
			}
		}

		&:hover {
			color: $bright-green;

			.icon {
				border: 1px solid $bright-green;
			}
		}
	}
}

#select_industry h2 {
	margin-bottom: 2rem;
}

.selector-box.connectivity {
	h2 {
		margin-bottom: 0;
	}

	p {
		margin: .3rem auto 3rem auto;
		width: 85%;
		font-size: .8rem;
		line-height: .9rem;
	}
}

.selector-box.contact-form {
	label {
		width: 90%;
		margin: 0 auto;
	}

	label input {
		border: 1px solid $bright-green;
	}

	.submit-button, #fld_7707851_1 {
		display: block;
		padding: .5rem 2rem;
		margin: 2.5rem auto;
		font-size: 1rem;
		text-transform: uppercase;
		border-radius: 0;
		border: 1px solid $bright-green;
	}
	#fld_7707851_1 {
		width: 48%;
	}
}

.selector-box.recommended {
	z-index: 1000;
	h2 {
		margin-bottom: 1rem;
	}

	p {
		font-size: rem-calc(14);
		width: 90%;
		margin: 0 auto;
	}

	.about p {
		font-size: rem-calc(12);
		line-height: 1.4em;
	}

	#recommended_devices {

	}

	.recommended-product {
		width: 90%;
		margin: 2rem auto;
		@include clearfix;

		.selected-device {
			display: block;
			height: auto;
			width: 60%;
			margin: 0 auto 1em auto;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

			&:after {
				content: "";
				display: block;
				width: 100%;
				padding-top: 100%;
			}
		}

		.product-title a {
			color: $bright-blue;

			&:hover {
				color: $bright-green;
			}
		}

		.product-info {
			width: 100%;
			text-align: center;
		}

		p {
			text-align: center;
			width: 100%;
			margin: 1em 0;
		}
	}
}

@include breakpoint(medium) {
	.selector-box {

		.back-next-buttons {
			.radio-back, .radio-next {
				padding: .5rem 3rem;
				font-size: 1.2rem;
				margin-bottom: 2rem;
			}

			.radio-back {
				margin-right: 1rem;
			}

			.radio-next {
				margin-left: 1rem;
			}
		}
	}

	.title-with-text-and-button {
		#toughbook_selector, #radio_selector {
			width: 75%;
		    height: 75%;
		    padding: 20px 0;
		    max-width: 425px;
		    font-size: 1.5rem;
		    margin-top: 1rem;
		}
	}

	.selector-box.contact-form {
		label {
			width: 70%;
		}

		.submit-button, 
		#fld_7707851_1 {
			padding: .5rem 3rem;
			font-size: 1.2rem;
			margin-bottom: 2rem;
		}
	}

	.selector-box.recommended {

		.recommended-product {
			width: 85%;
			.selected-device {
				width: 23%;
				margin: 0;
			}

			.product-info {
				width: 70%;
			}

			p {
				text-align: left;
			}

			&:nth-of-type(1){
				border-bottom: 1px solid $medium-gray;
				// border-bottom: 1px solid red;
				padding-bottom: 2rem;

				.selected-device {
					float: left;
				}

				.product-info {
					float: right;
				}
			}

			&:nth-of-type(3){
				border-top: 1px solid $medium-gray;
				padding-top: 2rem;
				// padding-bottom: 2rem;

				.selected-device {
					float: left;
				}

				.product-info {
					float: right;
				}
			}

			&:nth-of-type(2){
				.selected-device {
					float: right;
				}

				.product-info {
					float: left;
				}
			}
		}
	}
}

@include breakpoint(large) {
	.selector-box {

		&.checkboxes {

			.selector-list {

				.list-container {
					li {
						&:nth-of-type(3) {
							// margin-bottom: 0;
							// border: 1px solid blue;
						}
						label {
							margin-left: -1.5rem;

							
							
						}
					}
				}
			}
		}

		

		.selector-list {
			-moz-column-count: 2;
		    -moz-column-gap: 20px;
		    -webkit-column-count: 2;
		    -webkit-column-gap: 20px;
		    column-count: 2;
		    column-gap: 20px;
		    margin: 0 auto 3rem auto;
		    width: 90%; 
		    max-width: 605px;

		    .list-container {
		    	label {
		    		width: 17rem;
		    	}
		    }
		}

		.selector-list.rugged-list {
			-moz-column-count: 1;
		    -moz-column-gap: 20px;
		    -webkit-column-count: 1;
		    -webkit-column-gap: 20px;
		    column-count: 1;
		    column-gap: 20px;
		    margin: 0 auto 3rem auto;
		    width: 90%; 
		    max-width: 605px;
		}

		.back-next-buttons {
			.radio-back, .radio-next {
				padding: .5rem 4rem;
				margin-bottom: 3.5rem;
				margin-top: 2rem;
			}

			.radio-back{
				margin-right: 2rem;
				
			}

			.radio-next {
				margin-left: 2rem;
			}
		}

		.flex-wrapper {
			display: flex;
			justify-content: space-evenly;
		    width: 90%;
    		margin: 0 auto;
			
			.device-wrapper {
				text-align: center;
			}
		}
	}

	.selector-box.contact-form {
		.submit-button,
		#fld_7707851_1 {
			padding: .5rem 4rem;
			margin-bottom: 3.5rem;
			margin-top: 2rem;
		}
	}

	.selector-box.recommended {
		p {
			font-size: rem-calc(16);
		}

		.about p {
			font-size: rem-calc(14);
		}
	}
}

@include breakpoint(xlarge) {
	.selector-box {

		.back-next-buttons {
			.radio-back, .radio-next {
				padding: .5rem 6rem;
			}
		}

		.flex-wrapper {
			flex-direction: row;
		}
	}

	.selector-box.contact-form {
		label {
			width: 63%;
		}

		.submit-button,
		#fld_7707851_1 {
			padding: .5rem 6rem;
		}
	}
}