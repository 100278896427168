header.site-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: rem-calc(44);
  z-index: 999;
}

body.admin-bar header.site-header {
  top: 46px;
}

.nav-logo {
  display: block;
  width: rem-calc(100);
  padding: 0;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.content-block:nth-of-type(1){
  padding-top: rem-calc(44);
}

// Navigation breakpoints
.mobile-menu,
.site-title-bar {
  @include hide-for(medium);
}

.site-title-bar {
  height: 100%;
  background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0.8) 100%);
}

.mobile-menu {
  background-color: rgba(0,0,0,0.8);

  .empty-link .submenu-toggle {
    width: 100%;

    &:after {
      position: absolute;
      top: 50%;
      right: 14px;
      bottom: auto;
      left: auto;
      margin: 0;
      transform: translateY(-50%);
    }
  }
}

.desktop-menu,
.site-navigation .top-bar-left,
.site-navigation .top-bar-center {
  @include show-for(medium);
}

.top-bar-left,
.top-bar-center,
.top-bar-right {
  flex: none;
}

// Site title
.site-desktop-title a {
  font-weight: bold;
}

// Mobile menu
.menus {
  width: 100%;
}

.mobile-menu {
  display: none; // prevents repaint caused by JS hiding menu onload
}

.off-canvas {
  > ul.menu {
    height: 100vh;
    padding: 1rem;

    a {
      color: $white;
      font-weight: 600;
      font-size: rem-calc(15);
    }
  }

  .menu .active > a { background-color: #ccc; }

}

.top-bar-title {
  display: none;
}

.title-bar-title {
  a {
    margin-left: 0.66rem;
  }
}

.mobile-menu,
.mobile-off-canvas-menu {

  .menu .is-active > a {
    background: transparent;
    color: $bright-green;
  }
}

// Tablet and desktop menu

.top-bar {
  .menu a {
    color: $white;
    text-transform: uppercase;
    padding-top: 0;
    padding-bottom: 0;
    font-size: rem-calc(9);
    line-height: 1;

    &:hover:not(.button) {
      color: $bright-green;
    }
  }

  /* .menu li.empty-link > a:hover {
    color: $white;
  } */

  .menu .active > a,
  .menu .current_page_item > a,
  .menu .current-menu-parent > a {
    background: transparent;
    color: $bright-green;
  }

  .menu .current-menu-parent:not(.not-active) ul {
    display: block;
  }

  .menu>li:not(.menu-text)>a {
    padding: 0.25em 0.48em;
  }

  .mobile-menu {
    a {
      font-size: rem-calc(14);
    }

    li:not(.menu-text) > a {
      padding: 1em;
    }
  }

  // .menu li:not(:last-child) { border-right: 1px solid #4e4e4e; }

  .dropdown.menu .submenu { border: 0; }
  .dropdown.menu .has-submenu.is-down-arrow a { padding-right: 1rem; }
  .dropdown.menu .has-submenu.is-down-arrow > a::after { border: 0; }
  .dropdown.menu:first-child > li.is-dropdown-submenu-parent > a::after { display: none; }

  .dropdown.menu > a {
    color: $white;
  }

  .dropdown.menu .is-active > a {
    color: $bright-green;
  }
}

.site-navigation {
  @include breakpoint(small only) {
    padding: 0; // prevents container visibility on small screens
  }
}

// WP post navigation
.post-navigation {
  @include clearfix;
}

.nav-previous {
  float:left;
}

.nav-next {
  float:right;
}

// mobile search button
.mobile-search-button {
  width: rem-calc(26);
  height: rem-calc(26);
  margin-right: rem-calc(2);
  display: block;
  text-align: center;
  padding-top: rem-calc(3);
  color: $white;

  .fa {
    font-size: rem-calc(20);
  }

  &:hover,
  &:active,
  &:focus {
    color: $medium-gray;
  }
}

// search field
.site-header .search-field {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 95%;
  max-width: rem-calc(600);
  z-index: 99;
  display: none;
  padding-right: rem-calc(34);

  form {
    border: 1px solid rgba(0,0,0,0.25);
  }

  input {
    padding: rem-calc(6) rem-calc(12);
    border: none;
  }

  .input-group {
    margin: 0;
  }

  #searchsubmit {
    color: $white;
    background-color: $bright-blue;

    &:hover {
      background-color: $bright-green;
    }
  }

  .close-x {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: rem-calc(26);
    height: rem-calc(26);
    cursor: pointer;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 9%;
      background-color: $medium-gray;
      left: 0;
      top: 50%;
    }

    &:before {
      transform: translateY(-50%) rotate(-45deg);
    }

    &:after {
      transform: translateY(-50%) rotate(45deg);
    }

    &:hover {
      &:before,
      &:after {
        background-color: $bright-green;
      }
    }
  }
}

@include breakpoint(medium) {
  header.site-header {
    height: rem-calc(66);
  }

  .content-block:nth-of-type(1){
    padding-top: rem-calc(66);
  }

  .site-navigation {
    height: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 100%);
  }

  .top-bar {
    display: block;
    position: relative;

    .top-bar-title {
      display: block;
      flex: none;
      margin: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .menus {
      @include xy-grid-container;
      position: relative;
      @include clearfix;
      top: 50%;
      transform: translateY(-50%);

      & > ul {
        width: 50%;
        display: block;
        position: static;
        float: left;
      }

      ul:nth-of-type(1){
        text-align: right;
        padding-right: rem-calc(58);

        li:nth-of-type(1){
          display: none;
        }

        ul {
          li:nth-of-type(1){
            display: inline-block;
          }
        }
      }

      ul:nth-of-type(2){
        text-align: left;
        padding-left: rem-calc(58);
      }

      li {
        display: inline-block;
      }

      ul ul:nth-of-type(1),
      ul ul:nth-of-type(2) {
        text-align: center;
        padding: 0;
      }

      .menu-item.search {
        i {
          display: inline;
          color: $bright-green;
          margin-right: 0.3em;
        }
      }
    }

    .is-dropdown-submenu-parent {
      position: static;
    }

    .is-dropdown-submenu {
      background-color: rgba(0,0,0,0.45);
      
      li {
        display: inline-block;
        width: auto;

        a {
          padding-top: 0.4em;
          padding-bottom: 0.4em;
        }
      }
    }
  }
}

.dropdown.menu > li.opens-right > .is-dropdown-submenu,
.dropdown.menu > li.opens-left > .is-dropdown-submenu,
.dropdown.menu > li.opens-inner > .is-dropdown-submenu {
  width: 96%;
  left: 2%;
  top: 105%;
}

@include breakpoint(rem-calc(700)){
  .top-bar {
    .menu a {
      font-size: rem-calc(10.5);
    }

    .menu>li:not(.menu-text)>a {
      padding: 0.25em 0.66em;
    }

    .is-dropdown-submenu>li:not(.menu-text)>a {
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
}

@include breakpoint(rem-calc(783)){
  body.admin-bar header.site-header {
    top: 32px;
  }
}

@include breakpoint(large){
  .top-bar {
    .menu a {
      font-size: rem-calc(12);
    }

    .menus {
      ul:nth-of-type(1){
        padding-right: rem-calc(66);

        li:nth-of-type(1){
          display: inline-block;
        }
      }

      ul:nth-of-type(2){
        padding-left: rem-calc(66);
      }
    }
  }
}