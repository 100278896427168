footer.footer {
  background: $black;

  a {
    color: $white;

    &:hover {
      color: $bright-green;
    }
  }
}

.footer-container {
  @include xy-grid-container;
  color: $white;
}

.footer-grid {
  @include xy-grid;
  padding: rem-calc(25) 0;

  section {
    @include clearfix;
  }

  .footer-menu {
    @include xy-cell(12);
    @include clearfix

    .nav-logo,
    .menu,
    .copyright {
      float: left;
      clear: both;
    }

    .nav-logo {
      position: relative;
      transform: translateX(-2px);
    }
  }

  .footer.menu {
    margin-top: 1em;
    @include clearfix;

    li {
      a {
        padding: .5em;
        display: block;
        color: $white;
        background: transparent;
        text-transform: uppercase;
        font-size: rem-calc(12);

        &:hover {
          color: $bright-green;
        }

         #owners_edge_wrapper {
            // float: left;
            display: block;

            #owners_edge {
              width: 100%;
              height: auto;
              max-width: rem-calc(70);
              margin: rem-calc(5) 0;
            }
          }

        #baycom_footer_logo {
          clear: both;
          width: rem-calc(225);

          p {
            font-size: .7em;
          }
        }
      }
    }
  }

  #menu-item-491 {
    a {
      &:hover {
          text-decoration: none;
          color: white;
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .social-menu {
    @include xy-cell(12);
    @include clearfix;
    margin-top: rem-calc(30);

    .widget {
      margin-top: 1em;

      &:nth-of-type(1) {
        margin-top: 0;
      }
    }

    .social-links,
    .phone,
    .email {
      float: left;
      clear: both;
    }

    .social-links {
      li {
        display: inline-block;
        margin-right: 0.5em;

        a {
          background-color: $white;
          display: block;
          width: rem-calc(26);
          height: rem-calc(26);
          border-radius: 50%;
          color: $dark-blue;
          position: relative;

          &:hover {
            background-color: $bright-blue;
            color: $white;
          }

          i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            font-size: rem-calc(15);
          }
        }
      }
    }

    .phone,
    .email {
      display: block;
      text-transform: uppercase;
      position: relative;
      padding-left: rem-calc(30);
      margin-top: rem-calc(20);

      i {
        position: absolute;
        left: 0;
        top: 50%;
      }
    }

    .phone {
      i {
        font-size: 1.5em;
        transform: translateX(10%) translateY(-45%) rotate(-20deg);
      }
    }

    .email {
      i {
        font-size: 1.2em;
        transform: translateY(-60%);
        clear: both;
      }
    }

    #quick-support {
      position: relative; 
      width: 100px; 
      height: 50px;  
      clear: both;
      float: left;
      margin-top: 1.5em;
      a {
        text-decoration: none;
        span {
          position: absolute; 
          top: 17px; 
          left: 33px; 
          display: block; 
          cursor: pointer; 
          color: #424242; 
          font-family: Arial; 
          font-size: 9px; 
          line-height: 1.2em; 
          font-weight: bold; 
          text-align: center; 
          width: 70px;
        }
      }
    }
  }

  .copyright {
    text-transform: uppercase;
    margin-top: rem-calc(40);
    @include xy-cell(12, false);
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
    font-size: rem-calc(12);

    span, ul, li {
      display: inline-block;
    }

    ul.footer.menu {
      // margin: 0;
    }

    li {
      a {
        margin-left: 1.5em;
      }
    }
  }
}

#back_to_top {
  position: fixed;
  z-index: 99;
  right: rem-calc(16);
  bottom: rem-calc(16);
  background-color: $bright-blue;
  color: $white;
  width: rem-calc(48);
  height: rem-calc(48);
  border-radius: 50%;
  display: none;

  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: rem-calc(20);
  }
}


@include breakpoint(rem-calc(700)) {
  footer.footer {
    .footer-menu {
      @include xy-cell(7);
    }

    .social-menu {
      @include xy-cell(5);
      margin-top: 0;
    }

    .copyright {
      margin-left: 0.625rem;
      margin-right: 0.625rem;
    }
  }

  #back_to_top {
    right: rem-calc(20);
    bottom: rem-calc(20);
  }
}

@include breakpoint(rem-calc(700)) {
  .footer-grid {
    .copyright {
      span {
        margin-bottom: -6px;
      }
    }
    .footer.menu {
      li {
        a{
          #owners_edge_wrapper {
            display: block;

            #owners_edge {
              width: 100%;
              height: auto;
              display: block;
              max-width: rem-calc(70);
              margin: rem-calc(5) 0;
            }
          }

          #baycom_footer_logo {
            display: inline-block;
            padding-top: .6em;

            p {
              font-size: .7em;
            }
          }
        }
      }
    }
  }
}
