.block-wrap {
  @include xy-grid-container;
}

@include breakpoint(medium) {
}

@include breakpoint(large) {
}

@import "content-blocks/anchor-jump-menu";
@import "content-blocks/button-box";
@import "content-blocks/button-menu-with-title";
@import "content-blocks/form-with-title";
@import "content-blocks/header-spacer-image";
@import "content-blocks/hero-image-with-title";
@import "content-blocks/link-with-arrow";
@import "content-blocks/multi-column-content";
@import "content-blocks/picture-grid";
@import "content-blocks/product-details";
@import "content-blocks/product-table";
@import "content-blocks/product-two-column";
@import "content-blocks/product-type-summary";
@import "content-blocks/quotes";
@import "content-blocks/recent-posts";
@import "content-blocks/spacer";
@import "content-blocks/text-with-icons";
@import "content-blocks/timeline";
@import "content-blocks/tabs";
@import "content-blocks/title-with-text-and-button";
@import "content-blocks/toughbook-selector";
@import "content-blocks/network-selector";
@import "content-blocks/two-column-bullet-list";