.sites-button {
  background: $primary-color;
  font-size: 1.25rem;
  font-weight: bold;
  box-shadow: 0 -2px 0 rgba(0,0,0,0.2) inset;
  border-radius: 3px;
  border: none;
}

.button {
	border: 1px solid $bright-green;
	text-transform: uppercase;
	font-size: rem-calc(20);

	&:hover {
		color: $white;
		border: 1px solid $bright-green;
	}
}