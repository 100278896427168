.content-block.title-with-text-and-button {
	padding-top: rem-calc(48);
	padding-bottom: rem-calc(55);

	.block-inner-content {
		@include xy-cell(12);
		text-align: center;
	}
}

@include breakpoint(medium) {
	.content-block.title-with-text-and-button {
		.block-inner-content {
			@include xy-cell(10);
			@include xy-cell-offset(1);
		}
	}
}

@include breakpoint(large) {
	.content-block.title-with-text-and-button {
		.block-inner-content {
			@include xy-cell(8);
			@include xy-cell-offset(2);
		}
	}
}