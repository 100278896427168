.content-block.product-two-column {
	.block-inner-content {
		@include xy-grid;
		padding-top: rem-calc(42);
		padding-bottom: rem-calc(42);
	}

	.product-column {
		@include xy-cell(12);
		position: relative;
		padding-bottom: rem-calc(38);

		&.left {
			padding-bottom: rem-calc(88);
			border-bottom: 1px solid $medium-gray;
			margin-bottom: rem-calc(50);

			.link {
				bottom: rem-calc(57);
			}
		}
	}

	.product-image {
		width: 100%;
		max-width: rem-calc(400);
		height: auto;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		margin: 0 auto rem-calc(30) auto;

		a {
			display: block;
		}

		img {
			width: 100%;
			height: auto;
			opacity: 0;
		}
	}

	.product-summary {
		h3 {
			a {
				color: $bright-blue;
			}
		}

		p.description {
			font-size: rem-calc(14);
		}
	}
	
	.link {
		text-align: center;
		position: absolute;
		width: 100%;
		left: 0;
		bottom: rem-calc(7);
	}

	a.link-with-arrow {
		text-transform: uppercase;
		font-size: rem-calc(16);
		color: $bright-blue;

		.icon {
			border: 1px solid $bright-blue;
			border-radius: 50%;
			width: 1.6em;
			height: 1.6em;
			position: relative;
			display: inline-block;
			margin-left: 0.33em;
			top: 0.45em;

			.fa {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translateX(-50%) translateY(-50%);
			}
		}
	}

	&.first{
		.block-inner-content {
			padding-top: 0;
		}
	}
}

@include breakpoint(medium) {
	.content-block.product-two-column {
		.product-column {
			@include xy-cell(10);
			@include xy-cell-offset(1);
		}

		.product-image {
			max-width: rem-calc(560);
		}
	}
}

@include breakpoint(large) {
	.content-block.product-two-column {
		.product-column {
			@include xy-cell(5.5);
			@include xy-cell-offset(0);

			&.left {
				padding-bottom: rem-calc(38);
				border-bottom: none;
				margin-bottom: 0;

				.link {
					bottom: rem-calc(7);
				}
			}

			&.right {
				@include xy-cell-offset(1);
			}
		}

		.product-image {
			width: auto;
			max-width: none;
			position: relative;

			&:before {
				content: "";
				width: 100%;
				display: block;
				padding-top: 35%;
			}

			img {
				display: none;
			}

			a {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}

		.product-summary {
		}

		a.link-with-arrow {
			font-size: rem-calc(18);
		}
	}
}