.content-block.multi-column-content {
	h2 {
		@include xy-cell(12);
		margin: 0 0 rem-calc(30) 0;
		text-align: center;
	}

	&.transparent {
		background-color: transparent;
	}

	&.light-gray {
		background-color: $light-gray;
	}

	&.medium-gray {
		background-color: $medium-gray;
	}

	&.dark-gray {
		background-color: $dark-gray;
	}

	&.black {
		background-color: $black;
	}

	&.white {
		background-color: $white;
	}

	&.bright-blue {
		background-color: $bright-blue;
	}

	&.bright-green {
		background-color: $bright-green;
	}

	&.dark-blue {
		background-color: $dark-blue;
	}

	&.medium-gray,
	&.dark-gray,
	&.black,
	&.bright-blue,
	&.bright-green,
	&.dark-blue {
		color: $white;

		h1, h2, h3, h4, h5, h6, p, blockquote, a {
			color: inherit;
		}

		a {
			text-decoration: underline;
		}

		.content-column.bordered {
			border: 1px solid $white;
		}

		.content-column.light-gray.bordered {
			border: 1px solid $light-gray;
		}
	}

	&.transparent,
	&.light-gray,
	&.white {
		.content-column.bordered {
			border: 1px solid $dark-blue;
		}
	}

	&.dark-gray,
	&.black,
	&.dark-blue {
		a:hover {
			color: $bright-green;
		}
	}

	.block-inner-content {
		@include xy-grid;
		padding-top: rem-calc(42);
		padding-bottom: rem-calc(42);
	}

	/* .product-column {
		@include xy-cell(12);
	} */

	&.first{
		.block-inner-content {
			padding-top: 0;
		}
	}

	&.columns-count-1 {
		.content-column {
			@include xy-cell(12);
		}
	}

	&.columns-count-2 {
		.content-column {
			@include xy-cell(12);
		}
	}

	&.columns-count-3 {
		.content-column {
			@include xy-cell(12);
		}
	}

	.content-column {
		padding: rem-calc(40);
		margin-bottom: rem-calc(30);

		&:nth-last-of-type(1){
			margin-bottom: 0;
		}

		p:last-child {
			margin-bottom: 0;
		}

		&.transparent {
			background-color: transparent;
		}
		
		&.light-gray {
			background-color: $light-gray;
		}

		&.medium-gray {
			background-color: $medium-gray;
		}

		&.dark-gray {
			background-color: $dark-gray;
		}

		&.black {
			background-color: $black;
		}

		&.white {
			background-color: $white;
		}

		&.bright-blue {
			background-color: $bright-blue;
		}

		&.bright-green {
			background-color: $bright-green;
		}

		&.dark-blue {
			background-color: $dark-blue;
		}

		&.medium-gray,
		&.dark-gray,
		&.black,
		&.bright-blue,
		&.bright-green,
		&.dark-blue {
			color: $white;

			h1, h2, h3, h4, h5, h6, p, blockquote, a {
				color: inherit;
			}

			a {
				text-decoration: underline;
			}
		}

		&.light-gray,
		&.white {
			color: $black;

			h1, h2, h3 {
				color: $bright-blue;
			}

			h4, h5, h6, p, blockquote {
				color: inherit;
			}

			a {
				color: $links;
				text-decoration: none;
			}
		}
	}
}

@include breakpoint(medium) {
	.content-block.multi-column-content {
	}
}

@include breakpoint(rem-calc(760)) {
	.content-block.multi-column-content {
		&.columns-count-2 {
			.content-column {
				@include xy-cell(6);
				margin-bottom: 0;
			}
		}

		&.columns-count-3 {
			.content-column {
				@include xy-cell(6);

				&:nth-last-of-type(1){
					@include xy-cell(12);
				}
			}
		}
	}
}

@include breakpoint(large) {
	.content-block.multi-column-content {
	}
}

@include breakpoint(rem-calc(960)) {
	.content-block.multi-column-content {
		&.columns-count-3 {
			.content-column {
				@include xy-cell(4);
				margin-bottom: 0;

				&:nth-last-of-type(1){
					@include xy-cell(4);
				}
			}
		}
	}
}