// font-family: 'Open Sans', sans-serif;
// font-family: 'Open Sans Condensed', sans-serif;

h1 {
	font-family: 'Open Sans Condensed', sans-serif;
	font-weight: bold;
}

h1, h2, h3 {
	color: $bright-blue;
}

h1, h2, h3, h4, h5, h6 {
	text-transform: uppercase;
	line-height: 1.2em;
}

// FontAwesome - Currently loaded via CDN
// @import 'fontawesome';
// @import 'solid';
// @import 'brands';