.content-block.header-spacer-image {
	min-height: rem-calc(120);
	width: 100%;
	background-size: cover;
	background-position: center;

	h1 {
		text-align: center;
		color: $white;
		font-size: rem-calc(45);
		padding: rem-calc(36) 0 rem-calc(45) 0;
		margin: 0;
	}
}

@include breakpoint(medium) {
	.content-block.header-spacer-image {
		
	}
}

@include breakpoint(large) {
	.content-block.header-spacer-image {
		
	}
}