.content-block.two-column-bullet-list {
	background-color: $light-gray;
	padding-top: rem-calc(40);
	padding-bottom: rem-calc(40);

	h4 {
		color: $bright-blue;
		@include xy-cell(12);
		margin-bottom: 0.75em;
	}

	.list-columns {
		@include xy-grid;
	}

	.list-column {
		@include xy-cell(12);

		ul {
			margin-bottom: 0;
		}

		li {
			line-height: 1.4em;
			margin-bottom: 0.75em;
		}
	}
}

@include breakpoint(medium) {
	.content-block.two-column-bullet-list {
		
	}
}

@include breakpoint(large) {
	.content-block.two-column-bullet-list {
		h4 {
			@include xy-cell(10);
			@include xy-cell-offset(1);
		}

		.list-column {
			@include xy-cell(5);

			&.column-0 {
				@include xy-cell-offset(1);
			}
		}
	}
}