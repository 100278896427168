ul.pagination {
	.current, a {
		border: 1px solid rgba(0,0,0,0.2);
		padding: rem-calc(2) rem-calc(10);
		color: $dark-gray;
		display: block;

		.fa {
			font-size: rem-calc(12);
			color: $medium-gray;
		}
	}

	.current {
		background: $bright-blue;
		border: 1px solid $bright-blue;
		color: $white;
	}
}